
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import { RequestPaging, deepClone } from "@/utils/util";
import LsForm from "@/components/ls-form.vue";
import LsDialog from "@/components/ls-dialog.vue";

import MaterialSelect from "@/components/material-select/index.vue";

import {
  apiModelDesignPartsLists,
  apiModelDesignPartsAdd,
  apiModelDesignPartsEdit,
  apiModelDesignPartsDelete,
  apiModelMaterialList,
  apiModelDesignFastEdit,
  apiModelDetail,
} from "@/api/model";
import { flatten } from "@/utils/util";

const dataOrigin: any = {
  id: 0,
  zh_cn_name: "",
  en_name: "",
  bg_color: "",
  blend: 0,
  factory_thumb: 0,
  collection_type: 1,
  content_type: 1,
  crop_size: "800|800",
  delete_time: null,
  design_name: "",
  design_size: "800|800",
  designer_img_color: "",
  dotted_size: "",
  dpi: 72,
  envmap_show: 0,
  factory_img_ratio: "",
  factory_img_type: 1,
  img: "",
  img_suffix: "png",
  is_diy: 1,
  mask: "",
  metalness: "0.5",
  metalness_map: "",
  metalness_show: 0,
  min_size: "",
  model_color: "",
  model_id: 2,
  model_item_id: 0,
  model_type: 1,
  model_view: "",
  opacity: "1.0",
  overlay_path: "",
  production_size: "20|20",
  recommend_size: "",
  reserve_texture: 0,
  roughness: "0.5",
  roughness_map: "",
  roughness_show: 0,
  shininess: 30,
  side: 0,
  sort: 0,
  specular: "#ffffff",
  specular_map: "",
  specular_show: 0,
  status: 1,
  texture: "",
  tl_pointer: "",
  normalmap: "",
  sku_diy_data: "",
  more_spec: 0,
  depth_write: 1,
  alpha_test: 0.1,
  transparent: 1,
  material_type: "MeshStandardMaterial",
  texture_method: "",
};
@Component({
  components: {
    LsForm,
    LsDialog,
    MaterialSelect,
  },
})
export default class Dialog extends Vue {
  visible = {
    val: false,
  };

  @Prop() value: any;
  @Prop({ default: 0 }) spec_design: any;
  @Prop({ default: 0 }) model_id: any;
  @Prop({ default: 0 }) model_item_id: any;
  visibleVal = this.visible;
  // props
  @Prop({ default: "" }) title!: string; // 弹窗标题
  @Prop({ default: false }) disabled!: boolean; // 是否禁用
  @Prop({ default: false }) async!: boolean; // 是否开启异步关闭
  @Prop({ default: true }) clickModalClose!: boolean; // 点击遮罩层关闭对话窗口
  pager: RequestPaging = new RequestPaging({ size: 99 });

  activeName = "professional"; //professional,fast

  mode = "add";
  formVisible = false;
  normalmap = { id: "", img: "", scale: 1, repeat: 1 };
  normalmapList: any = [];

  dataForm: any = dataOrigin;
  is_diy = -10;

  skuDiyData = [];

  isdiyList = [
    { value: -10, label: "全部" },
    { value: 0, label: "否" },
    { value: 1, label: "是" },
  ];

  material_type_list = [
    { value: "MeshBasicMaterial", label: "基础网格材质" },
    { value: "MeshStandardMaterial", label: "标准材质" },
    { value: "MeshPhongMaterial", label: "Phong网格材质" },
    { value: "MeshPhysicalMaterial", label: "物理网格材质" },
    { value: "MeshLambertMaterial", label: "Lambert网格材质" },
    { value: "MeshMatcapMaterial", label: "MeshMatcap材质" },
  ];

  @Watch("activeName")
  changeActiveName(val: any) {
    this.is_diy = -10;
  }
  // methods
  handleEvent(type: "cancel" | "confirm") {
    if (!this.async || type === "cancel") this.close();
    if (type === "confirm") {
    }
  }

  getList() {
    const params: any = {
      model_id: this.model_id,
      page_size: 50,
      sort: "sort desc",
      is_fast: this.activeName == "fast" ? 1 : 0,
      is_diy: this.is_diy,
    };
    if (this.spec_design) {
      // params.model_item_id = this.model_item_id;
    }
    this.pager.request({
      callback: apiModelDesignPartsLists,
      params: params,
    });
  }

  onTrigger() {
    if (this.disabled) return;
    this.open();
  }

  close() {
    this.visible.val = false;
    this.$emit("close");
  }

  open() {
    this.visible.val = true;
    this.$emit("open");
    this.getList();
    apiModelMaterialList({ page_size: 999 }).then((res) => {
      this.normalmapList = res.lists;
    });
  }
  // 设置字段名称
  setFields(prev: any, next: any) {
    let valueArr: any[] = [prev, next];
    valueArr = valueArr.filter((item) => item.value !== undefined);
    const ids = flatten(valueArr.map((item) => item.ids)).join();
    const value = flatten(valueArr.map((item) => item.value));
    return {
      id: prev.id ? prev.id : "",
      ids: ids,
      value,
      spec_value_str: value.join(),
      image: prev.image ? prev.image : next.image,
      cost_price: prev.cost_price ? prev.cost_price : "",
      volume: prev.volume ? prev.volume : 0,
      weight: prev.weight ? prev.weight : 320,
      length: prev.length ? prev.length : 20,
      width: prev.width ? prev.width : 15,
      height: prev.height ? prev.height : 3,
    };
  }
  getTableData(arr: any[]) {
    arr = JSON.parse(JSON.stringify(arr));
    return arr.reduce(
      (prev, next) => {
        const newArr: any[] = [];
        for (let i = 0; i < prev.length; i++) {
          if (!next.length) {
            newArr.push(this.setFields(prev[i], {}));
          }
          for (let j = 0; j < next.length; j++) {
            next[j].ids = j;
            newArr.push(this.setFields(prev[i], next[j]));
          }
        }
        return newArr;
      },
      [{}]
    );
  }
  getSkuDiyData() {
    return new Promise((resolve, reject) => {
      let sku_diy_data_copy = this.dataForm.sku_diy_data
        ? deepClone(JSON.parse(this.dataForm.sku_diy_data))
        : [];
      let sku_diy_data = this.dataForm.sku_diy_data
        ? JSON.parse(this.dataForm.sku_diy_data).map((item: any) => item.name)
        : [];
      apiModelDetail({ id: this.model_id }).then((res) => {
        const specList = res.spec_value.map((item: any) => item.spec_list);
        let spec_list = this.getTableData(specList);
        // let spec_list = res.spec_value_list;
        let skuDiyDataCopy: any = [];
        for (let specIndex in spec_list) {
          let spec = spec_list[specIndex];
          if (sku_diy_data.includes(spec.spec_value_str) > 0) {
            skuDiyDataCopy[specIndex] = sku_diy_data_copy.find(
              (item: any) => item.name == spec.spec_value_str
            );
          } else {
            skuDiyDataCopy[specIndex] = {
              name: spec.spec_value_str,
              img: "",
              mask: "",
              design_size: "800|800",
              dotted_size: "",
              production_size: "20|20",
              img_suffix: "png",
              dpi: "72",
              recommend_size: "",
            };
          }
        }
        this.skuDiyData = skuDiyDataCopy;
        resolve(skuDiyDataCopy);
      });
    });
  }

  async handleAdd() {
    this.mode = "add";
    this.dataForm = deepClone(dataOrigin);
    await this.getSkuDiyData();
    this.formVisible = true;
  }

  async handleEdit(row: any) {
    this.dataForm = row;
    delete this.dataForm.create_time;
    delete this.dataForm.update_time;
    delete this.dataForm.delete_time;
    delete this.dataForm.model_name;
    this.mode = "edit";
    this.normalmap = JSON.parse(this.dataForm.normalmap);
    await this.getSkuDiyData();

    this.formVisible = true;
  }

  handleChangeValue(id: number, value: number, key: string) {
    apiModelDesignFastEdit({ id: Number(id), [key]: value }).then((res) => {
      this.$message.success("success");
      this.getList();
    });
  }

  changeNormalMap(value: any) {
    if (value === "") {
      this.normalmap.id = "";
      this.normalmap.img = "";
    } else {
      const val = this.normalmapList[value];
      this.normalmap.id = value;
      this.normalmap.img = val.image;
    }
  }

  handleSubmit() {
    this.dataForm.model_id = this.model_id;
    // this.dataForm.model_item_id = this.model_item_id;
    this.dataForm.normalmap = JSON.stringify(this.normalmap);
    if (this.dataForm.more_spec == 1) {
      this.dataForm.sku_diy_data = JSON.stringify(this.skuDiyData);
    }
    if (this.mode == "add") {
      this.dataForm.is_fast = this.activeName == "fast" ? 1 : 0;
      apiModelDesignPartsAdd(this.dataForm).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    } else {
      apiModelDesignPartsEdit(this.dataForm).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    }
  }

  handleDelete(id: number) {
    apiModelDesignPartsDelete({ id: id }).then((res) => {
      this.getList();
    });
  }

  handleCopy(row: any) {
    let params = deepClone(row);
    delete params.id;
    delete params.create_time;
    delete params.update_time;
    delete params.delete_time;
    params.sort = 1;
    apiModelDesignPartsAdd(params).then((res) => {
      this.getList();
    });
  }

  created() {}
}
